import React from "react";
import { useStyle } from "./app.navbar.style";
import { AppBar, Toolbar, Container, Button, Typography } from "@mui/material";
import clsx from 'clsx';
import { IsMobileWidth, IsTabletWidth } from '../utill/utils';
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../../lottie-animations/TeraByte Logo.json';
import ScrollAnimatedLottie from '../../../components/common/scroll.animation.lottie/scroll.animation.lottie';
// import AnimatedTextV2 from "../animated.text.v2/animated.text.v2";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AppNavbar = () => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()

    return (
        <div className="position-relative">
            <AppBar position="relative" classes={{ root: classes.appNavbar }} className='m-auto'>
                <Container maxWidth='xl' className='p-0'>
                    <Toolbar className={clsx(!mobileWidth && "d-flex flex-row align-items-center justify-content-between")}>
                        <div className="cursor-pointer">
                            <div>
                                <ScrollAnimatedLottie
                                    width={!mobileWidth ? '201.48px' : '150px'}
                                    height={!mobileWidth ? '57.6px' : '43px'}
                                    animationData={Logo}
                                />
                            </div>
                        </div>
                        {/* <div className="cursor-pointer">
                            <Link to="home" spy={true} smooth={true} offset={50} duration={500} delay={500}>
                                <img
                                    height='57.6px'
                                    width='57.6px'
                                    src={`${process.env.PUBLIC_URL}/assets/images/logo-globe.svg`}
                                ></img>
                                <img
                                    height='39.08px'
                                    width='138.12px'
                                    src={`${process.env.PUBLIC_URL}/assets/images/logo-terabyte.svg`}
                                ></img>
                            </Link>
                        </div> */}
                        {!mobileWidth ?
                            <div>
                                <Button className={clsx(classes.buttonStyleNav, "text-transform-none")}>
                                    <Typography variant="subtitle1">
                                        Let’s Talk
                                    </Typography>
                                    <LazyLoadImage
                                        alt="arrow-icon"
                                        height='100%'
                                        effect="blur"
                                        width='13px'
                                        src={`${process.env.PUBLIC_URL}/assets/images/arrow-forward.svg`}
                                    />
                                </Button>
                            </div>
                            : null
                        }
                    </Toolbar>
                </Container>
            </AppBar>
        </div >
    );
};
export default AppNavbar;