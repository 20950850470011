import React, { useState } from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper, Typography } from '@mui/material'
import { useStyle } from './footer.style'
import { IsMobileWidth, IsTabletWidth } from '../../../components/common/utill/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Footer = (props) => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    const redirect = (url) => {
        props.push(url);
    }
    return (
        <Paper elevation={0} className={clsx(classes.footer, 'm-auto', !mobileWidth && 'pt-5 pb-5', mobileWidth && 'pt-3 pb-3')}>
            <Container maxWidth='xl' className={clsx(!mobileWidth && 'pb-5')}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className={clsx(classes.gridStyle, "cursor-pointer")}>
                                <LazyLoadImage
                                    effect="blur"
                                    alt="globe-icon"
                                    height='57.6px'
                                    width='57.6px'
                                    src={`${process.env.PUBLIC_URL}/assets/images/logo-globe.svg`}
                                />
                                <LazyLoadImage
                                    effect="blur"
                                    alt="tera-icon"
                                    height='39.08px'
                                    width='138.12px'
                                    src={`${process.env.PUBLIC_URL}/assets/images/logo-terabyte.svg`}
                                />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={clsx(classes.gridStyle, 'd-flex flex-column')}>
                            <Typography color='primary'
                                variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle20400', tabletWidth && 'subtitle20400', mobileWidth && 'body2')}>
                                Made <b style={{ color: '#FCA311' }}>with</b> 🖤 by TeraBytes
                            </Typography>
                            <Typography className='pt-2 pb-2' color='primary' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', tabletWidth && 'body2', mobileWidth && 'body3')}>
                                {`© All Right Reserve by TeraBytes |${new Date().getFullYear()}  All rights reserved.
                                Company Number 08284793, Lightfoot house, Lightfoot lane, Pr4 0ae `}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className={clsx(classes.gridStyle, 'd-flex')}>
                            <div className={clsx('w-100 d-flex justify-content-center gap-32')}>
                                <div className="cursor-pointer">
                                    <a>
                                        <LazyLoadImage
                                            effect="blur"
                                            src={`${process.env.PUBLIC_URL}/assets/images/facebook.svg`}
                                            height='48px'
                                            width='48px'
                                            alt="fb-icon"
                                        />
                                    </a>
                                </div>
                                <div className="cursor-pointer">
                                    <a>
                                        <LazyLoadImage
                                            effect="blur"
                                            src={`${process.env.PUBLIC_URL}/assets/images/linkedin.svg`}
                                            height='48px'
                                            alt="linkedin-icon"
                                            width='48px'
                                        />
                                    </a>
                                </div>
                                <div className="cursor-pointer">
                                    <a>
                                        <LazyLoadImage
                                            effect="blur"
                                            src={`${process.env.PUBLIC_URL}/assets/images/instagram.svg`}
                                            height='48px'
                                            alt="instagram-icon"
                                            width='48px'
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

export default Footer