import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    appNavbar: {
        background: theme.palette.background.paper,
        // height: '72px',
        left: '0px',
        top: "0px",
        boxShadow: 'none !important',
    },
    responsiveAppNavbar: {
        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
        height: 'auto',
        left: '0px',
        top: "0px",
        boxShadow: 'none !important',
    },
    toolbar: {
        padding: "0px"
    },
    buttonStyleNav: {
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        textTransform: 'none',
        borderRadius: 100,
        gap: 10,
        border: `1.5px solid ${theme.palette.primary.main}`,
        padding: '10px 38px 10px 38px',
        width: '191px',
        boxShadow: 'none',
        display:'flex',
        alignItems:'center',
        // opacity: 0.4,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: '#fff',
            border: `1.5px solid ${theme.palette.secondary.main}`,
            opacity: 1,
            boxShadow: 'none',
        }
    }
}))
