import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedLottie = React.forwardRef((props, ref) => {
    const { animationData, width, height } = props;
    const [hasPlayed, setHasPlayed] = useState(false);
    const [refInView, inView] = useInView();
    const controls = useAnimation();
    const lottieRef = useRef(null);

    useEffect(() => {
        if (inView && !hasPlayed) {
            setHasPlayed(true);
            controls.start({ opacity: 1, scale: 1 }); // Start animation when in view

            if (lottieRef.current) {
                // Ensure lottieRef.current is an instance of Lottie
                if (lottieRef.current.play) {
                    lottieRef.current.play();
                }
            }
        }
    }, [inView, hasPlayed, controls]);

    // Assign refInView to the provided ref
    useEffect(() => {
        if (ref) {
            ref.current = refInView;
        }
    }, [refInView, ref]);

    return (
        <motion.div style={{ height: height ? height : '100%', width: width ? width : '100%' }} ref={refInView} initial={{ opacity: 0, scale: 0 }} animate={controls}>
            <div style={{ height: '100%', width: '100%' }} ref={(element) => (lottieRef.current = element)}>
                {/* {animationData && animationData?.assets && animationData.assets?.length > 0 ? */}
                    <Lottie
                        animationData={animationData}
                        loop={!hasPlayed} // Set loop to true only if it hasn't played before
                    /> 
            </div>
        </motion.div>
    );
});

export default AnimatedLottie;
